import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import useTranslation from '../utils/i18n/useTranslation';

type ConfirmModalType = {
  confirm: (
    title: string,
    message?: string,
    confirmButton?: string,
    confirmButtonProps?: ButtonProps,
    cancelButton?: string
  ) => Promise<boolean>;
};

const defaultConfirmModalData = {
  confirm: (): Promise<boolean> => {
    throw new Error('確認モーダルが実装されていません');
  },
};

const ConfirmModalContext = createContext<ConfirmModalType>(defaultConfirmModalData);

export const ConfirmModalProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<ReactNode>(undefined);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const createOpener = useCallback(
    (
      title: string,
      message?: string,
      confirmButton?: string,
      confirmButtonProps?: ButtonProps,
      cancelButton?: string
    ): Promise<boolean> =>
      new Promise((resolve) => {
        const handleClose = () => {
          setModal(undefined);
          resolve(false);
        };

        const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          setModal(undefined);
          resolve(false);
        };

        const handleOk = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          setModal(undefined);
          resolve(true);
        };

        setModal(
          <AlertDialog
            size={{ base: 'xs', md: 'md' }}
            isOpen={true}
            leastDestructiveRef={cancelButtonRef}
            trapFocus={false}
            onClose={handleClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='md'>{title}</AlertDialogHeader>

                {message && <AlertDialogBody whiteSpace='pre-wrap'>{message}</AlertDialogBody>}

                <AlertDialogFooter>
                  <Button
                    mr={3}
                    variant='ghost'
                    onClick={handleCancel}
                    ref={cancelButtonRef}
                    color='gray.500'
                    backgroundColor='gray.200'
                    size={{ base: 'sm', md: 'md' }}
                  >
                    {cancelButton ? cancelButton : t('actions.cancel')}
                  </Button>
                  <Button
                    onClick={handleOk}
                    colorScheme='error'
                    size={{ base: 'sm', md: 'md' }}
                    {...confirmButtonProps}
                  >
                    {confirmButton ? confirmButton : t('confirmation.confirmed')}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        );
      }),
    [t]
  );
  const context = useMemo(
    () => ({
      confirm: createOpener,
    }),
    [createOpener]
  );

  return (
    <ConfirmModalContext.Provider value={context}>
      {children}
      {modal}
    </ConfirmModalContext.Provider>
  );
};

export const useConfirmModal = () => useContext(ConfirmModalContext);
